import {reset} from './appState'

export default {
  reset (state) {
    Object.assign(state, reset(state))
  },

  loaded (state) {
    state.loaded = true
  },

  setCurrentBO (state, bo) {
    state.currentBO = bo
  },

  setCriterion(state, payload) { state.currentBO.currentCriterion = payload },

  setTerm(state, payload) { state.currentBO.term = payload.trim() },

  setSheet(state, {sheet, term, criterion}) {
    state.currentBO.sheet = sheet; state.currentBO.sheetTerm = term; state.currentBO.sheetCriterion = criterion;
  },

  setEntries(state, {boIndex, payload}) { state.businessObjects[boIndex].entries = payload },

  setActionRights (state, {boIndex, rights}) {
    if (!!~boIndex){
      Object.keys(state.businessObjects[boIndex].rightsForAction)
      .forEach(key => {
        state.businessObjects[boIndex].rightsForAction[key] = {
          ...state.businessObjects[boIndex].rightsForAction[key],
          value: rights[key] !== undefined ? rights[key] : false
        }
      })
    }
  }
}
