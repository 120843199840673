class UserStore {
  constructor() {
    this.$token = ''
    this.$user = undefined
    this.$requestPath = undefined
  }

  get token() {
    return this.$token || ''
  }

  set token(token) {
    this.$token = token || ''
  }

  get user() {
    return this.$user || null
  }

  set user(user) {
    if (user && (!('job' in user) || !('format' in user) || !('siteCode' in user) || !('idSiteAllocation' in user))) {
      user = cleanupSite(cleanupJob(user))
      user.firstName = user?.firstName?.toLowerCase() || ''
      user.lastName = user?.lastName?.toLowerCase() || ''
      localStorage.setItem('userData', JSON.stringify(user))
    }
    this.$user = user || null
  }

  get requestPath() {
    this.$requestPath = this.$requestPath || localStorage.getItem(KEYS.REQUEST_PATH)
    return this.$requestPath
  }

  set requestPath(requestPath) {
    this.$requestPath = requestPath
    requestPath !== undefined ? localStorage.setItem(KEYS.REQUEST_PATH, requestPath) : localStorage.removeItem(KEYS.REQUEST_PATH)
  }

  get isCentral() {
    return this.user && Number(this.user.idSiteAllocation) === 997
  }
}

export default new UserStore()

function cleanupJob(user) {
  if (!user) return user
  const labelJob = user.labelJob
  if (labelJob && labelJob.length) {
    const splitteLabelJob = labelJob.split('|')
    if (splitteLabelJob.length === 2) {
      user.job = splitteLabelJob[1]
    }
  }
  return user
}

function cleanupSite(user) {
  if (!user) return user
  const codeSite = user.codeSite || 'FRA997'

  user.format = user.businessUnit === 'SUPER' ? 2 : 1 // Formats: hyper (1), proxy (2)

  user.siteCode = codeSite
  user.idSiteAllocation = substrSiteCode(codeSite, user.format)
  return user
}

function substrSiteCode(codeSite, format) {
  return +codeSite.substring(codeSite.length - (format === 2 /* 2 is proxy format */ ? 4 : 3), codeSite.length)
}
