import { mapGetters } from 'vuex'
import i18n from './product.i18n'
import moment from 'moment'

export default {
  data () {
    return {
      i18n
    }
  },
  computed: {
    ...mapGetters(['sheet']),

    technical () {
      return {
        id: this.sheet.id,
        cui: this.sheet.data.cui,
        updatingDate: this.sheet.technicalData.updatingDate,
        sendingDate: this.sheet.technicalData.sendingDate
      }
    },

    labelsAndNomenclature () {
      return {
        taggingLabel : this.sheet.data.balisageLabel,
        ticketLabel : this.sheet.data.ticketLabel,
        nomenclatureInfoSector : this.sheet.nomenclatureInfoSector,
        nomenclatureInfoDepartment : this.sheet.nomenclatureInfoDepartment,
        nomenclatureInfoUnderDepartment : this.sheet.nomenclatureInfoUnderDepartment,
        nomenclatureInfoFamily : this.sheet.nomenclatureInfoFamily,
        brandLabel : this.sheet.data.brandLabel,
        siteLabel : this.sheet.data.siteLabel,
        referenceLevel : this.sheet.data.referenceLevel,
        type : this.sheet.data.type
      }
    },

    booleans () {
      return {
        isOrder: this.sheet.attribute.isOrder,
        isSale: this.sheet.attribute.isSale,
        isPromo: this.sheet.attribute.isPromo,
        isMeasure: this.sheet.attribute.isMeasure,
        isDeclined: this.sheet.attribute.isDeclined,
        isEanSupplier: this.sheet.attribute.isEanSupplier,
        isLot: this.sheet.attribute.isLot,
        isVariant: this.sheet.attribute.isVariant
      }
    },

    packaging () {
      return {
        numberOfCopies: this.sheet.packaging?.numberOfCopies,
        numberOfComponents: this.sheet.packaging?.numberOfComponents,
        totalComponents: this.sheet.packaging?.totalComponents,
        numberOfTaCo: this.sheet.packaging?.numberOfTaCo,
        facing: concat(this.sheet.packaging?.facing?.toFixed(2), this.sheet.packaging?.unitMeasure),
        height: concat(this.sheet.packaging?.height?.toFixed(2), this.sheet.packaging?.unitMeasure),
        depth: concat(this.sheet.packaging?.depth?.toFixed(2), this.sheet.packaging?.unitMeasure),
        grossWeight: concat(this.sheet.packaging?.grossWeight?.toFixed(2), this.sheet.packaging?.unitWeight),
        netWeight: concat(this.sheet.packaging?.netWeight?.toFixed(2), this.sheet.packaging?.unitWeight)
      }
    },

    datesAndState () {
      const now = moment()
      const stoppingDate = moment(this.sheet.data.stoppingDate, 'YYYY-MM-DD')
      const removingDate = moment(this.sheet.data.removingDate, 'YYYY-MM-DD')

      return {
        creationDate: this.sheet.data.creationDate,
        stoppingDate: this.sheet.data.stoppingDate,
        reasonOfStopping: this.sheet.data.reasonOfStopping,
        removingDate: this.sheet.data.removingDate,
        willBeStopped: !!this.sheet.data.stoppingDate && stoppingDate.isAfter(now),
        willBeRemoved: !!this.sheet.data.removingDate && removingDate.isAfter(now),
        isStopped: (!!this.sheet.data.stoppingDate && stoppingDate.isBefore(now)) || this.sheet.data.isStopped,
        isRemoved: !!this.sheet.data.removingDate && removingDate.isBefore(now) || this.sheet.data.isRemoved,
        isResend: this.sheet.data.isResend
      }
    }
  }
}

function concat (...values) {
  return values.map(value => value === null || value === undefined ? '' : value).join(' ').trim()
}
