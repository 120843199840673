import { BusinessObjectConfiguration, Criterion } from './bo.conf'

export default class GrantAccessConfiguration extends BusinessObjectConfiguration {
  constructor() {
    super({
      label: 'Habilitations',
      type: 'grantAccess',
      criteria: [new Criterion({type: 'Matricule', pattern: /(^([Aa][pP])?\d{5}$)/}), new Criterion({type: 'Nom'}), new Criterion({type: 'Prénom'})],
      svg: require('!svg-inline-loader!@/assets/svg/grantAccess.svg'),
      mandatoryEntries: new Set([]),
      rightsForAction: {},
      noSearch: true
    })
  }
}
