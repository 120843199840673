<template>
  <div class="welcome">
    <template v-if="error">
      <img src="~@/assets/images/close.svg" alt="">
      <div class="text">Ooops, une erreur est survenue ...</div>
    </template>
    <template v-else-if="notFound">
      <img src="~@/assets/images/error-404.svg" alt="">
    </template>
    <template v-else>
      <img src="~@/assets/images/logo.svg" alt="">
      <div class="text">Bonjour {{name.charAt(0)  + name.slice(1).toLowerCase()}}, <br/> bienvenue sur Hibou !</div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    name:null,
    error: { default: false },
    notFound: { default: false }
  }
}
</script>
<style lang="scss" scoped>
.welcome {
  text-align: center;

  img {
    height: 10em;
    display: block;
    margin: 2em auto;
  }

  .text {
    font-size: 3em;
    font-weight: bold;
    text-shadow: 0 0 0 rgba(#fff, 0.5), 1px -1px 0px rgb(194, 194, 194);
    color: transparent;
  }
}
</style>

