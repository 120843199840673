<template>
  <form v-if="!bo.noSearch"
        ref="searchForm"
        class="search"
        :class="!!error ? 'on-error' : ''"
        :data-info="error || info"
        @submit.prevent="search(term)">
    <span class="icon-btn">
      <i v-if="!loading" class="icon fa fa-search" @click="search(term)" />
      <i v-else class="icon fa fa-circle-o-notch fa-spin" />
    </span>
    <input ref="term"
           spellcheck="false"
           name="term"
           :pattern="criterion.pattern.source"
           :value="term.trim()"
           @input="setTerm($event.target.value); error=''">
    <input type="submit" hidden>
    <span v-for="ctrn of bo.criteria"
          :key="bo.type + ':' + ctrn.type"
          v-tooltip.bottom-end="ctrn.hint"
          class="criterion"
          :class="{current: ctrn === criterion}"
          @click="setCriterion(ctrn), $refs.term.focus()">
      {{ ctrn.type }}
    </span>
  </form>
</template>

<script>
  import genericService               from '@/services/generic.service'
  import messageService               from '@/services/messaging.service'
  import { mapGetters, mapMutations } from 'vuex'

  export default {
    data() {
      return {
        info: '',
        error: '',
        loading: false
      }
    },

    computed: {
      ...mapGetters(['bo', 'criterion', 'term'])
    },

    watch: {
      bo() {
        this.error = ''
      }
    },

    mounted() {
      this.$refs.term && this.$refs.term.focus()
    },

    methods: {
      ...mapMutations(['setSheet', 'setCriterion', 'setTerm']),

    async search(event) {
      if (this.term) {
        this.bo.beforeSearch(this.bo)
        const errors = this.bo.validate(this.bo, this.criterion)
        if (errors && errors.length) {
          this.error = errors.join('<br>')
          return
        }

        this.error  = ''
        let sheet
        try {
          this.loading = true
          sheet = await genericService.getSheet({bo: this.bo.type, criterion: this.criterion.type, term: this.term.trim()})
        } catch (e) {}
          finally { this.loading = false }
        if (sheet && sheet.id) {
          this.bo.globalFilter.column = ''
          this.bo.globalFilter.value = null
          this.setSheet({sheet, criterion: this.criterion, term: this.term})
          this.$router.push({
            name: 'hibou-result',
            params: {
              bo: this.bo.type, criterion: this.criterion.type, term: this.term
            }
          })
        } else {
          this.error = `${this.bo.label.charAt(0).toUpperCase()}${this.bo.label.substring(1, this.bo.label.length - 1)} non trouvé`
          messageService.showError(this.error + ` pour le critère "${this.criterion.type}" et la valeur "${this.term}"`)
          this.setSheet({})
          this.$router.push({ path: '/'})
        }
      }
      return false
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/sass/main";

  .search {
    background-color : rgba($search-color, 0.1);
    align-self       : flex-end;
    display          : flex;
    align-items      : center;
    margin-left      : 5em;
    border-radius    : 3em;
    position         : relative;
    height           : 30px;

    .icon-btn {
      padding       : 0 1em;
      color         : $search-color;
      align-self    : stretch;
      display       : inline-flex;
      align-items   : center;
      cursor        : pointer;
      transition    : background-color 0.2s;
      border-radius : 3em 0 0 3em;

      &:hover {
        background-color : rgba(0, 0, 0, 0.1);

        &:hover + input {
          border-color : transparent;
        }
      }
    }

    input {
      padding-left : 4px;
      color        : $search-color !important;
      border-left  : 1px solid rgba(0, 0, 0, 0.1);
    }

    .criterion {
      display     : inline-flex;
      text-align  : center;
      align-items : center;
      padding     : 0 1em;
      height      : 100%;
      cursor      : pointer;

      &:first-of-type {
        border-left : 1px solid RGBA(110, 129, 151, 0.1);

        &.current {
          border-left-color : transparent;
        }
      }

      &:last-child {
        border-radius : 0 3em 3em 0;
      }

      &.current {
        color            : #fff;
        background-color : lighten($current-selection-color, 0%);
        cursor           : default;
      }
    }

    &::after {
      content   : "" attr(data-info) "";
      position  : absolute;
      top       : calc(100% + 2px);
      right     : 1em;
      font-size : 0.7rem;
    }

    &.on-error {
      border : 1px solid $error-text-color-alpha;

      &::after {
        color : $error-text-color;
      }
    }
  }
</style>
