import chipmunkService from '@/services/chipmunk.service'
import genericService from '@/services/generic.service'
import hibouService from '@/services/hibou.service'
import userStorageService from '@/user/userStore'

export default {
  init({ commit, getters, dispatch }) {
    return new Promise(async resolve => {
      try {
        for (let [i, bo] of getters.businessObjects.entries()) {
          if (!bo.entries || !bo.entries.length) {
            const [defaultParameters, userParameters] = await Promise.all([
              chipmunkService.getDefaultRoleParameters({ parameterName: bo.type + '_input' }),
              chipmunkService.getUserParameters({
                parameterName: bo.type + '_input',
                userCode: userStorageService.user.employeeNumber
              })
            ])
            let entries = await genericService.getEntries({
              bo: bo.type,
              entryIds: [...defaultParameters, ...userParameters],
              mandatoryEntries: bo.mandatoryEntries
            })
            commit('setEntries', { boIndex: i, payload: entries })
          } // if
          dispatch('setRightsForAction', { userCode: userStorageService.user.employeeNumber, boType: bo.type })
        } // for..of
      } catch (e) {
        console.log(e)
      }
      resolve()
    })
  },

  reset({ commit }) {
    commit('reset')
  },

  setRightsForAction({ commit, getters }, { userCode, boType }) {
    chipmunkService.getRightsForAction(userCode)
                   .then(rights => {
                     commit('setActionRights', { boIndex: getters.boIndexByType(boType), rights })
                   })
  },

  makeAction({ getters }, { type, boType, payload }) {
    hibouService.execAction(boType, type, payload)
  }
}
