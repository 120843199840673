<template>
  <div v-if="sheet && sheet.id" class="actions">
    <div class="actions-wrapper">
      <div v-for="action of actions" :key="action.type" class="action" @click="onAction(action.type)">
        <i class="fa" :class="action.icon" aria-hidden="true" v-html="!action.icon ? action.svg : ''" /> {{ action.label }}
      </div>
    </div>
    <transition name="slide-in-DownUp">
      <ui-reporting-form v-show="showReportView" class="reporting" :history="history" @new-report="onAction('userCanAddReport__DONE', $event)" />
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ReportComposer from '@/components/ReportComposer'
import hibouService from '@/services/hibou.service'
import userStorageService from '@/user/userStore'

export default {
  components: {
    'ui-reporting-form': ReportComposer
  },

  props: ['user'],

  data() {
    return {
      showReportView: false,
      history: []
    }
  },

  computed: {
    ...mapGetters(['bo', 'sheet', 'actions']),
  },

  created() {
    this.getReportHistory()
    this.$parent.$on('hibou-result-update', this.getReportHistory)
  },

  methods: {
    ...mapActions(['makeAction']),

    async getReportHistory() {
      if (!(this.sheet && this.sheet.id)) return
      if (!this.actions.find(action => action.type === 'userCanAddReport')) return
      try {
        let data = await hibouService.getActionReportHistory(this.bo.type, this.sheet.id)
        this.history = data || []
        console.log(`[${this.bo.type} (${this.sheet.id})] fetch reports history`)
      } catch (e) {}
    },

    onAction(type, payload = {referenceId: this.bo.sheet.id, userCode: userStorageService.user.employeeNumber}) {
      switch (type) {
        case 'userCanDetachEAN':
        case 'userCanAskRepublish':
          this.makeAction({type, boType: this.bo.type, payload: {...payload, site: this.user && this.user.site && this.user.siteSid}})
          break
        case 'userCanAddReport__DONE': // internal action
          let newReport = {
            message: payload,
            referenceId: this.bo.sheet.id,
            userCode: this.user.uid,
            userName: `${this.user.displayName}`,
            creationDate: null,
            status: null
          }
          this.makeAction({
            type: type.substring(0, type.lastIndexOf('__DONE')),
            boType: this.bo.type,
            payload: {
              productReport: newReport,
              userCode: null
            }
          })
          this.showReportView = false
          break
        case 'userCanAddReport':
          this.showReportView = !this.showReportView
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/sass/main";

.actions {
  position: relative;

  .actions-wrapper {
    font-size: 0.8em;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    margin: auto;
    background-color: $action-bar-color;
    color: #fff;
  }

  .action {
    transition: background-color .4s;
    padding: .5em 1rem;
    min-width: 6em;
    text-align: center;
    font-weight: bold;
    display: flex;
    align-items: center;

    i { margin-right: 4px; }

    :global(i svg) {
      width: 2em;
      fill: currentColor;
    }

    &:hover {
      background-color: rgba(#FFF, 0.8);
      color: $action-bar-color;
      cursor: pointer;
    }
  }

  > .reporting {
    @extend %with-shadow;
    z-index: 9999;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
  }
}
</style>
