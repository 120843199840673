import EventBus from '@/bus/event.bus'
import { throttle } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  props: {
    data: {
      default: () => ({ columns: [], values: [] })
    }
  },

  data() {
    const filters = {}
    this.data.columns.forEach(column => {
      const header = column.field
      filters[header] = {
        search: '',
        values: this.valuesByHeader(header),
        selected: []
      }
    })

    return {
      currentPage: 1,
      pageSize: 15,
      pageSizes: [15, 25, 50],
      isScrollable: false,
      isScrollableToLeft: false,
      isScrollableToRight: false,
      parentIsScrolling: false,
      sort: {
        direction: 0,
        header: ''
      },
      filters,
      currentFilter: null,
      parentScrollListenerIsActive: true
    }
  },

  mounted() {
    this.$nextTick(this.tableScroll)

    EventBus.$off('hibou-scroll', this.doParentIsScrolling)
    EventBus.$on('hibou-scroll', this.doParentIsScrolling)
  },

  watch: {
    pageSize() {
      this.currentPage = 1

    },

    'filteredLines.length'() {
      this.currentPage = 1
      this.tableScroll()
    }
  },

  computed: {
    ...mapGetters(['globalFilter']),

    headers() {
      return this.data ? this.data.columns.map(column => column.field) : []
    },

    columns() {
      return this.data.columns
    },

    filteredLines() {
      let { column: gColumn, value: gValue } = this.globalFilter
      gValue = toString(gValue)
      const hasGColumn = (this.headers.indexOf(gColumn) > -1)
      const doGlobalFilter = gColumn && gValue && hasGColumn

      const lines = (this.data && this.data.values ? this.data.values : []).filter(line => {
        for (let filter in this.filters) {
          if (this.filters.hasOwnProperty(filter)) {
            if (this.filters[filter].selected.length
              && !this.filters[filter].selected.some(f => toString(f) === toString(line[filter]))) {
              return false
            }
          }

          if (doGlobalFilter && gValue !== toString(line[gColumn])) {
            return false
          }
          if (gValue && !gColumn && !hasGColumn && !Object.values(line).some(value => toString(value).includes(gValue))) {
            return false
          }
        }
        return true
      })

      const direction = this.sort.direction
      const header = this.sort.header
      if (direction && header) {
        return lines.sort((a, b) => {
          const va = a[header]
          const vb = b[header]
          if (va !== null && va !== undefined && vb !== null && vb !== undefined) {
            return va > vb ? direction : va < vb ? -direction : 0
          } else if (va !== null && va !== undefined && (vb === null || vb === undefined)) {
            return direction
          } else if ((va === null || va === undefined) && vb !== null && vb !== undefined) {
            return -direction
          }
          return 0
        })
      }

      //this.data.columns.forEach(column => {
      //  const header                = column.field
      //  this.filters[header].values = this.valuesByHeader(header, lines)
      //})
      return lines
    },

    lines() {
      const start = (this.currentPage - 1) * this.pageSize
      return this.filteredLines.slice(start, start + this.pageSize)
    },

    totalPages() {
      const division = this.filteredLines.length / this.pageSize
      return Number.isInteger(division) ? division : Math.floor(division) + 1
    },

    currentFilterValues() {
      if (this.currentFilter) {
        const filter = this.filters[this.currentFilter]
        if (filter.search) {
          return filter.values.filter(value => {
            return value && value.toLowerCase().indexOf(filter.search.toLowerCase()) > -1
          })
        }
        return filter.values
      }
      return []
    }
  },

  methods: {
    resetFilters() {
      this.data.columns.forEach(column => {
        const header = column.field
        this.filters[header] = {
          search: '',
          values: this.valuesByHeader(header),
          selected: []
        }
      })
    },

    canShowHeaderFilter(header) {
      return this.currentFilter === header
    },

    goto(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page
        this.$forceUpdate()
      }
    },

    doSort(header) {
      if (this.sort.header !== header) {
        this.sort.direction = 1
        this.sort.header = header
      } else {
        this.sort.direction = this.sort.direction === 0 ? 1 : this.sort.direction === 1 ? -1 : 0
      }
    },

    valuesByHeader(header, lines) {
      const set = new Set(
        //this.data.values.map(line => line[header])
        (lines || this.data.values).map(line => line[header])
      )
      if (set.has(null) || set.has(undefined) || set.has('')) {
        set.delete(null)
        set.delete('')
        set.delete(undefined)
        set.add('')
      }

      return [...set].sort((a, b) => (a > b) ? 1 : (a < b) ? -1 : 0)
    },

    setFilterPosition(index) {
      const popup = this.$refs.filter[index]
      const th = this.$refs.th[index].getBoundingClientRect()
      popup.style.transform = `translate(${ th.left }px, ${ th.bottom }px)`
      popup.style.width = th.width + 'px'
    },

    moveCurrentFilter() {
      if (this.currentFilter) {
        const index = this.headers.indexOf(this.currentFilter)
        if (index > -1) {
          this.setFilterPosition(index)
        }
      }
    },

    tableScroll: throttle(function() {
      if (this.filteredLines.length) {
        this.$nextTick(() => {
          const left = this.$refs.wrapper?.scrollLeft
          const right = this.$refs.wrapper?.scrollWidth - this.$refs?.wrapper.clientWidth
          this.isScrollableToLeft = left > 0
          this.isScrollableToRight = left < right
        })
      } else {
        this.isScrollableToLeft = false
        this.isScrollableToRight = false
      }
      if (this.parentScrollListenerIsActive) {
        this.hideFilter()
      }
    }, 200),

    doParentIsScrolling() {
      if (this.parentScrollListenerIsActive) {
        this.currentFilter = ''
      } else {
        this.moveCurrentFilter()
      }
    },

    toggleFilter(index) {
      const newFilter = this.headers[index]
      if (this.currentFilter === newFilter) {
        this.currentFilter = null
      } else {
        this.currentFilter = this.headers[index]
        this.focus(this.$refs.filter[index])
        this.setFilterPosition(index)
      }
    },

    deselectAllInFilter(header) {
      if (header in this.filters) {
        this.filters[header].selected = []
      }
    },

    selectAllInFilter(header) {
      if (header in this.filters) {
        this.filters[header].selected = [...this.filters[header].values]
      }
    },

    toggleFilterSelection(header) {
      const filter = this.filters[header]
      if (filter.selected.length) {
        this.deselectAllInFilter(header)
      } else {
        this.selectAllInFilter(header)
      }
    },

    hideFilter() {
      this.currentFilter = null
    },

    hideFilterIfOut() {
      setTimeout(() => {
        const index = this.headers.indexOf(this.currentFilter)
        const filterSearch = this.$refs.filterSearch[index]
        const filter = this.$refs.filter[index]
        if (document.activeElement !== filterSearch && document.activeElement !== filter) {
          this.hideFilter()
        }
      }, 100)
    },

    ignoreParentScroll() {
      this.parentScrollListenerIsActive = false
      setTimeout(() => {
        this.parentScrollListenerIsActive = true
      }, 200)
    },

    focus(el) {
      el.focus()
      setTimeout(() => el.focus(), 10)
    }
  }
}

function toString(value) {
  if (value === undefined || value === null) {
    return ''
  }
  return `${ value }`.toLowerCase().trim()
}
