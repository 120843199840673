export default {
  technical: {
    id: 'NSU_RIN',
    cui: 'CUI',
    updatingDate: 'MAJ',
    sendingDate: 'Renvoyé le'
  },
  
  labelsAndNomenclature: {
    taggingLabel: 'Libellé balisage',
    ticketLabel: 'Libellé d\'encaissement',
    nomenclatureInfoSector: 'Secteur',
    nomenclatureInfoDepartment: 'Rayon',
    nomenclatureInfoUnderDepartment: 'Sous-rayon',
    nomenclatureInfoFamily: 'Famille',
    brandLabel: 'Marque',
    siteLabel: 'Site Admin',
    referenceLevel: 'Niveau Réf',
    type: 'Type'
  },
  
  booleans: {
    isOrder: 'Commandable',
    isSale: 'Vendable',
    isPromo: 'Promo',
    isMeasure: 'Mesure Variable',
    isDeclined: 'Déclinée',
    isEanSupplier: 'EAN géré fournisseur',
    isLot: 'Lot',
    isVariant: 'Variante'
  },
  
  
  packaging: {
    numberOfCopies: 'Nb exemplaire',
    numberOfComponents: 'Nb de composantes',
    totalComponents: 'Nb total de composants',
    numberOfTaCo: 'Nb de ta/co dans CMS',
    facing: 'Facing',
    height: 'Hauteur',
    depth: 'Profondeur',
    grossWeight: 'Poids brut',
    netWeight: 'Poids net'
  },
  
  datesAndState: {
    creationDate: 'Créé le',
    stoppingDate: 'Arrêté le',
    reasonOfStopping: 'Motif d\'arrêt',
    removingDate: 'Supprimé le'
  }
}

