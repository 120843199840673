import axios from 'axios'
import Vue from 'vue'


class UserService {
  constructor() {

    this.requestInterceptor = [
      async config => {
        const token = await Vue.prototype.$user.getIdToken()
        config.headers.common.Authorization = `Bearer ${ token }`
        config.headers.project_id = process.env.VUE_APP_FIREBASE_PROJECT_ID
        config.headers.application = process.env.VUE_APP_APP_NAME
        config.headers.country = process.env.VUE_APP_COUNTRY

        return config
      },
      error => Promise.reject(error)
    ]

    this.responseInterceptor = [
      response => response,
      err => {
        // use AuthSaml.addInterceptors to handle 401, 403 & 503 HTTP status
        // this.authenticator.addInterceptors(err.response)
        return Promise.reject(err)
      }
    ]
  }

  createAuthenticatedResource(config) {
    const instance = axios.create(config)
    instance.interceptors.request.use(...this.requestInterceptor)
    instance.interceptors.response.use(...this.responseInterceptor)
    return instance
  }
}

export default new UserService()
