<script>
    export default {
        name: 'VDelayedLoop',

        props: {
            iterable: {
                default: () => []
            },

            chunkSize: {
                default: 50
            },

            delay: {
                default: 10
            },

            tag: {
                default: 'div'
            }
        },

        data() {
            return {
              iterable$: null,
                cursor: null,
                chunks: [],
                timer: null
            }
        },

        watch: {
            iterable: {
              immediate: true,
              handler(iterable) {
                if (typeof iterable === 'number') {
                  this.iterable$ = Array.from({length: iterable}, (_, index) => index)
                } else {
                  this.iterable$ = iterable
                }
                clearTimeout(this.timer)
                this.timer  = null
                this.cursor = 0
                this.chunks = []
                this.loop()
              }
            }
        },

        destroyed() {
            clearTimeout(this.timer)
        },

        methods: {
            loop() {
                const end = this.cursor + this.chunkSize
                this.chunks.push(this.iterable$.slice(this.cursor, end))
                if (end < this.iterable$.length) {
                    this.cursor = end
                    this.timer  = setTimeout(() => this.loop(), this.delay)
                }
            }
        },

        render(h) {
            const children = []
            this.chunks.forEach((chunk, i) => {
                chunk.forEach((item, j) => {
                    const child = this.$scopedSlots.default({ item, index: j + (chunk.length * i) })
                    children.push(child)
                })
            })
            return h(this.tag, children)
        }
    }
</script>