import Vue       from 'vue'
import Vuex      from 'vuex'
import actions   from './actions'
import state     from './appState'
import getters   from './getters'
import mutations from './mutations'

Vue.use(Vuex)

const modules = {}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules
})
