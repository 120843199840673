import {BusinessObjectConfiguration, Criterion} from './bo.conf'

export default class ProductConfiguration extends BusinessObjectConfiguration {
  constructor() {
    super({
      label: 'produits',
      type: 'products',
      criteria: [new Criterion({type: 'CUG'}), new Criterion({type: 'EAN'}), new Criterion({type: 'CUI'})],
      icon: 'fa-barcode',
      beforeSearch: bo => {
        if (bo.term.length < 9) {
          bo.term = `000000000${bo.term}`.substr(-9)
        }
      },
      rightsForAction: {
        userCanDetachEAN: {
          value: false,
          label: 'Libérer EAN',
          icon: '',
          svg: require('!svg-inline-loader!@/assets/images/release-ean.svg')
        },
        userCanAddReport: {
          value: false,
          label: 'Signaler',
          icon: 'fa-comment'
        },
        userCanAskRepublish: {
          value: false,
          label: 'Republier',
          icon: 'fa-repeat'
        }
      }
    })
  }
}
