import apis from '@/apis'

class EntryDataCache {
  constructor() {
    this.innerCache = {}
  }

  getOrInitBOCache(boType) {
    if (!this.innerCache[boType]) {
      this.innerCache[boType] = {}
    }
    return this.innerCache[boType]
  }

  getOrInitRefIdCache(boType, refId) {
    let boCache = this.getOrInitBOCache(boType)
    if (!boCache[refId]) {
      boCache[refId] = new Map()
    }
    return boCache[refId]
  }

  getEntryData(boType, refId, entryId) {
    return this.getOrInitRefIdCache(boType, refId).get(entryId)
  }

  setEntryDataCache(boType, refId, entryId, data) {
    this.getOrInitRefIdCache(boType, refId).set(entryId, data)
  }
}

let cache = new EntryDataCache()

export default {
  getSheet({ bo, criterion, term }) {
    const url = [bo, 'search', criterion === '*' ? null : criterion, term].filter(el => el).join('/')
    return apis.hibou.get(url).then(response => response.data).then(
      sheet => {
        sheet && sheet.id && console.log(`[${ bo }/${ criterion }/${ term }] fetch sheet ${ sheet.id }`)
        return sheet
      }
    )
  },

  getEntries({ bo, entryIds = [0], mandatoryEntries = new Set() }) {
    const url = [bo, 'entries', entryIds].join('/')
    return apis.hibou.get(url).then(response => response.data).then(entries => {
      const localEntries = JSON.parse(localStorage.getItem(bo + ':selectedEntries') || '[]')
      return entries?.map(entry => ({
        ...entry,
        isMandatory: mandatoryEntries.has(entry.id),
        selected: mandatoryEntries.has(entry.id) || localEntries.includes(entry.id),
        table: null
      }))
    })
  },

  getTable({ bo, boId, entryId }) {
    const url = [bo, boId, 'entries', entryId, 'table'].filter(el => el).join('/')
    let cacheForRefId = cache.getOrInitRefIdCache(bo, boId)
    if (cacheForRefId.has(entryId)) {
      return Promise.resolve(cacheForRefId.get(entryId))
    }

    return apis.hibou.get(url).then(response => response.data)
               .then(table => {
                 // Here we are sure `cache.innerCache[bo][boId]` has been initialized with `cache.getOrInitRefIdCache()`

                 // Wipe some cache past a certain size
                 let boRefIdsCached = Object.keys(cache.innerCache[bo])
                 if (boRefIdsCached.length > 4) {
                   boRefIdsCached.forEach(id => {
                     if (+id !== boId) delete cache.innerCache[bo][+id]
                   })
                 }
                 let entryIdsCached = cache.innerCache[bo][boId]
                 if (entryIdsCached.size > 15) {
                   Array.from(entryIdsCached.keys()).forEach(cachedEntryId => {
                     if (cachedEntryId !== entryId) cache.innerCache[bo][boId].delete(cachedEntryId)
                   })
                 }
                 // Cache newly retrieved table
                 cache.setEntryDataCache(bo, boId, entryId, table)
                 return table
               })
  },

  getTables({ bo, boId, entryIds }) {
    return Promise.all(
      entryIds.map(entryId => this.getTable({ bo, boId, entryId }))
    )
  }
}
