import genericService from '@/services/generic.service'
import store from '@/stores'
import messageService from '@/services/messaging.service'

const notFound = (next, msg= `Nous n'avons pas trouvé ce que vous cherchez.. Veuillez utiliser la recherche`) => {
  messageService.showWarn(msg)
  next('/404')
}

const boDataResolver = async(to, next) => {
  const boType = to.params.bo
  const criterion = to.params.criterion
  const term = to.params.term

  let bo = store.getters.boByType(boType)
  if (!bo) return notFound(next)

  let needSearch = false
  if (bo.type !== store.getters.bo.type) needSearch = true
  if (criterion !== store.getters.bo.sheetCriterion?.type || term !== store.getters.bo.sheetTerm) needSearch = true
  // if a sheet is available, no need to fetch it
  if (!needSearch && store.getters.sheet && store.getters.sheet.id)  {
    return next()
  }
  let criterionObj = store.getters.criterionByType(criterion)(bo) || store.getters.bo.criteria[0]
  store.commit('setCurrentBO', bo)
  store.commit('setCriterion', criterionObj)
  store.commit('setTerm', term)
  // if no sheet is present, fetch it with route params
  try {
    const sheet = await genericService.getSheet({criterion, term, bo: boType }).catch(e => null)
    if (sheet && sheet.id) {
      store.commit('setSheet', {sheet, criterion: criterionObj, term})
      next()
    } else {
      notFound(next)
    }
  } catch (e) {
    return notFound(next)
  }
}

export default boDataResolver
