import Table from '@/components/Table'
import Modal from '@/components/base/Modal'
import SyncLoader from 'vue-spinner/src/SyncLoader'
import apis from '@/apis'
import i18n from './grant-access.i18n'
import habilitationForm from './habilitationForm/habilitationForm.vue'
import chipmunkService from '@/services/chipmunk.service'

export default {
  components: {
    'ui-table': Table,
    'sync-loader': SyncLoader,
    modal: Modal,
    'habilitation-form': habilitationForm
  },
  data() {
    return {
      roleColumns: [
        {
          field: i18n.headers.userCode,
          info: i18n.headers.userCode,
          type: 'text',
          id: 'userCode'
        },
        {
          field: i18n.headers.firstName,
          info: i18n.headers.firstName,
          type: 'text',
          id: 'firstName'
        },
        {
          field: i18n.headers.lastName,
          info: i18n.headers.lastName,
          type: 'text',
          id: 'lastName'
        },
        {
          field: i18n.headers.roles,
          info: i18n.headers.roles,
          type: 'text',
          id: 'role.role'
        }
      ],
      actionColumns: [
        {
          field: 'Matricule',
          info: 'Matricule',
          type: 'text',
          id: 'userCode'
        },
        { field: 'Prénom', info: 'Prénom', type: 'text', id: 'firstName' },
        { field: 'Nom', info: 'Nom', type: 'text', id: 'lastName' },
        {
          field: 'Actions',
          info: 'Actions',
          type: 'text',
          id: 'action.action'
        }

      ],

      habilitations: {
        columns: [],
        values: []
      },
      isActionsView: false,
      isLoading: false,
      isAddMode: false,
      isEditMode: false,
      isModalVisible: false,
      selectedItem: {},
      usersData: [],
      modal: {
        header: i18n.modal.delete.header,
        footer: {
          delete: i18n.modal.delete.footer.delete,
          cancel: i18n.modal.delete.footer.cancel
        }
      }
    }
  },

  computed: {
    label() {
      return !this.isActionsView ? i18n.labels.roles : i18n.labels.actions
    }
  },

  created() {
    this.getHabilitations()
  },

  methods: {
    async getHabilitations() {
      try {
        this.isLoading = true
        let roles = []
        const rolesData = await chipmunkService.getHabilitations(this.isActionsView)
        if (rolesData) {
          roles = await this.getUsersInfo(rolesData)
        }
        this.isLoading = false
        this.populateRoleTable(roles)
        this.$forceUpdate()
      } catch (err) {
        this.isLoading = false
      }
    },

    async getUsersInfo(arr) {
      if(!this.usersData.length) {
        this.usersData = (await apis.users.get(`_search?uids=${ arr.map(role => role.userCode) }`)).data
      }
      let roles = await Promise.all(
        arr.map(async role => {
          let userCode = role.userCode
          let user = this.usersData.find(item => item.uid === userCode)
          try {
            if (user?.givenName && user?.iirBirthname) {
              role.firstName = user.givenName
              role.lastName = user.iirBirthname
            }
          } catch (err) {
            console.log(`l'utilisateur ${ userCode } est introuvable`)
          }
          return role
        })
      )
      return roles.filter(role => role.firstName && role.lastName)
    },

    addNewHabilitation() {
      this.isAddMode = true
      this.isEditMode = false
    },

    populateRoleTable(arr) {
      this.habilitations.columns = this.isActionsView ? this.actionColumns : this.roleColumns
      this.habilitations.values = []
      this.habilitations.values = arr.map(item => {
        let line = {}
        this.habilitations.columns.forEach(col => {
          line[col.field] = this.deepGet(item, col.id)
        })
        line.item = item
        return line
      })
    },

    deepGet(obj, path) {
      path = path.split('.')
      let current = obj
      while (path.length) {
        if (typeof current !== 'object' || current === null) return undefined
        current = current[path.shift()]
      }
      return current
    },

    toggleView() {
      this.isActionsView = !this.isActionsView
      this.isAddMode = false
      this.isEditMode = false
      this.getHabilitations()
    },

    exportCSV() {
      const CSV_SEPARATOR_CHAR = ';'
      const CSV_END_OF_LINE_CHAR = '\n'
      const UTF8_HEADER = '\ufeff'
      const fields = this.habilitations.columns.map(column => column.field)
      const header = fields.join(CSV_SEPARATOR_CHAR) + CSV_END_OF_LINE_CHAR
      const values = this.habilitations.values.map(line =>
        fields.map(header => line[header]).join(CSV_SEPARATOR_CHAR)
      ).join(CSV_END_OF_LINE_CHAR)

      const blob = new Blob([UTF8_HEADER, header, values], {
        type: 'text/csv;charset=utf-8;'
      })
      const a = document.createElement('a')
      a.setAttribute('style', 'display: none')
      document.body.appendChild(a)
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = `habilitations.csv`
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    },

    async editHabilitation(line) {
      this.isEditMode = true
      this.isAddMode = false
      this.selectedItem = line
    },

    async deleteHabilitation() {
      try {
        await chipmunkService.deleteHabilitation(this.selectedItem, this.isActionsView)
        this.isModalVisible = false
        await this.getHabilitations()
      } catch (err) {
        console.log(err)
      }
    },

    showConfirmation(item) {
      this.selectedItem = item
      this.modal.body = `${ i18n.modal.delete.body } ${ item.item.firstName } ${ item.item.lastName } ?`
      this.isModalVisible = true
    },

    closeModal() {
      this.isModalVisible = false
      this.selectedItem = null
    },

    cancel() {
      this.isAddMode = false
      this.isEditMode = false
    }
  }
}
