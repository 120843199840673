import Vue from 'vue'
import Router from 'vue-router'

import Hibou from '@/views/Hibou'
import Welcome from '@/views/Welcome'
import Maintenance from '@/views/maintenance-page'

import resolver from '@/services/bo-resolver.service'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Hibou
    },
    {
      path: '/:bo/:criterion/:term', name: 'hibou-result', component: Hibou,
      beforeEnter: (to, from, next) => {
        resolver(to, next)
      }
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance
    },
    {
      path: '/error',
      name: 'error',
      component: Welcome,
      props: route => ({error: true, notFound: false})
    }, {
      path: '**',
      name: '',
      component: Welcome,
      props: route => ({error: false, notFound: true})
    },
  ]
})

function skipAuth(route) {
  let skip = isError(route)
  return skip
}

function isError(route) {
  console.log(route.name)
  return route.name === 'error' || route.name === 'maintenance'
}

export default router
