import Vue from 'vue'

const defaultOptions = {
  id: 'hibou-toast',
  className: ['hibou-toast'],
  horizontalPosition: 'right',
  verticalPosition: 'bottom',
  duration: 3500,
  closeable: true,
  transition: 'slide-in-right'
}

function makeMsg(title, message, type) {
  return [
    `
    <div class="toast-message">
      <span class="toast-image fa fa-2x
        ${type === 'success' ? 'fa-check' : ''}
        ${type === 'error' ? 'fa-close' : ''}
        ${type === 'warn' ? 'fa-exclamation-circle' : ''}
        ${type === 'info' ? 'fa-info-circle' : ''}"></span>
      <div class="toast-wrapper">
        <h3 class="toast-title">${title}</h3>
        <p class="toast-content">${message}</p>
      </div>
      <div style="clear: both;"></div>
    </div>
    `,
    { ...defaultOptions, className: defaultOptions.className.concat(`toast-${type}`) }
  ]
}

export default {
  showSuccess(message = 'Opération réussie !') {
    Vue.toast(...makeMsg('Succès', message, 'success'))
  },

  showInfo(message) {
    Vue.toast(...makeMsg('Information', message, 'info'))
  },

  showWarn(message) {
    Vue.toast(...makeMsg('Attention', message, 'warn'))
  },

  showError(message= 'Une erreur est survenue...') {
    Vue.toast(...makeMsg('Erreur', message, 'error'))
  },

  showAll(messages) {
    for (let message of messages) {
      this.show(message.severity, message.content)
    }
  },

  show(severity, message) {
    const severities = [
      'success',
      'warn',
      'error',
      'info'
    ];

    switch (Number.isInteger(severity) ? severities[severity] : severity) {
      case 'success':
        this.showSuccess(message)
        break;
      case 'info':
        this.showInfo(message)
        break;
      case 'warn':
        this.showWarn(message)
        break;
      case 'error':
        this.showError(message)
        break;
      default:
        break;
    }
  }
}
