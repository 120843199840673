import apis from '@/apis'
import messageService from '@/services/messaging.service'

export default {
  getActionReportHistory(boType, referenceId) {
    const url = [boType, 'actions', 'reports', boType, referenceId].join('/')
    return apis.hibou
      .get(url)
      .then(response => response.data)
      .then(data => (data ? data.productReports : []))
  },

  execAction(boType, actionType, payload) {
    let url = [boType, 'actions']
    switch (boType) {
      /**
       * PRODUCTS ACTIONS
       */
      case 'products':
        switch (actionType) {
          case 'userCanDetachEAN':
            url = url.concat('detach')
            break
          case 'userCanAskRepublish':
            url = url.concat('republish')
            break
          case 'userCanAddReport':
            url = url.concat('reports')
            break
        }
        break
    }

    apis.hibou
      .post(url.join('/'), payload)
      .then(response => {
        messageService.show(response.data.severity, response.data.message)
      })
      .catch(() => messageService.showError('Action non prise en compte.'))
  }
}
