import apiAuth from '@/user/userService'
import EventBus from '@/bus/event.bus'


const hibouResource = apiAuth.createAuthenticatedResource({
  baseURL: process.env.VUE_APP_BACKEND_ENDPOINT
})


const usersResource = apiAuth.createAuthenticatedResource({
  baseURL: process.env.VUE_APP_USERS_ENDPOINT
})

hibouResource.interceptors.request.use(config => {
  EventBus.$emit('wait')
  return config
}, error => {
  EventBus.$emit('stop-waiting')
  return Promise.reject(error)
})

hibouResource.interceptors.response.use(config => {
  EventBus.$emit('stop-waiting')
  return config
}, error => {
  EventBus.$emit('stop-waiting')
  return Promise.reject(error)
})


export default {
  hibou: hibouResource,
  users: usersResource
}
