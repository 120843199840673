import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

const db = firebase.firestore()
const paramsCollection = db.collection('PARAMETERS')
const actionsCollection = db.collection('ACTIONS')
const rolesCollection = db.collection('ROLES')
const usersCollection = db.collection('USERS')

export default {
  async getRoleParametersByRoleId({ parameterName, collection, roleId }) {
    let params = []
    let promises = []
    for await (let role of roleId.replaceAll(' ', '').split(',')) {
      promises.push(await collection.doc(role).get())
    }
    let paramsSnapshots = await Promise.all(promises)
    paramsSnapshots.forEach(paramsSnapshot => {
      if (paramsSnapshot.exists) {
        let data = paramsSnapshot.data()[parameterName]
        if (data) {
          params = [...params, ...data.replaceAll(' ', '').split(',')]
        }
      }
    })
    return params
  },

  async getDefaultRoleParameters({ parameterName }) {
    return await this.getRoleParametersByRoleId({ parameterName, collection: paramsCollection, roleId: '2' })
  },

  async getUserParameters({ parameterName, userCode }) {
    const usersSnapshot = await usersCollection.doc(userCode).get()
    if (usersSnapshot.exists) {
      let data = usersSnapshot.data()['roles']
      if (data) {
        return await this.getRoleParametersByRoleId({
          parameterName,
          collection: paramsCollection,
          roleId: data?.toString() || '2'
        })
      }
    }
    return []
  },

  async getRightsForAction(userCode) {
    const rightsForAction = await usersCollection.doc(userCode).get()
    if (rightsForAction) {
      const rights = rightsForAction.data()?.['actions']?.split(',') || []
      let rightsMap = {}
      const rightsMapping = {
        '2': 'userCanAskRepublish',
        '1': 'userCanAddReport',
        '3': 'userCanDetachEAN',
        '5': 'userCanAskRepublish'
      }
      rights.filter(r => !!r).forEach(item => {
        rightsMap[rightsMapping[item]] = true
      })
      return rightsMap
    } else return {}

  },

  async getHabilitations(isActionView) {
    try {
      let userSnapshot = await usersCollection.get()
      let habilitations = []
      userSnapshot.forEach(doc => {
        habilitations.push({
          userCode: doc.id,
          roles: doc.data()?.['roles'],
          actions: doc.data()?.['actions']
        })
      })
      let roles = this.splitArray(habilitations, 'roles', 'roleId')
      const rolePromises = await roles.map(async user => {
        user.role = await this.getRoleById(user.roleId)
        user.id = user.roleId
        return user
      })
      let actions = this.splitArray(habilitations, 'actions', 'actionId')
      const actionPromises = await actions.map(async user => {
        user.action = await this.getActionById(user.actionId)
        user.id = user.actionId
        return user
      })
      await Promise.all([...rolePromises, ...actionPromises])
      if (isActionView) {
        return actions.filter(action => action?.action?.isVisible) || actions
      } else {
        return roles.filter(role => role?.role?.isVisible) || roles
      }
    } catch (err) {
      console.log(err)
      return []
    }
  },

  async getRoleById(id) {
    if (id) {
      const roleSnapshot = await rolesCollection.doc(id.toString()).get()
      if (roleSnapshot.exists) {
        return roleSnapshot.data()
      }
    }
    return {}
  },

  async getActionById(id) {
    if (id) {
      const actionSnapshot = await actionsCollection.doc(id.toString()).get()
      if (actionSnapshot.exists) {
        return actionSnapshot.data()
      }
    }
    return {}
  },

  splitArray(arr, propKey, propDest) {
    return arr.reduce((acc, obj) => acc.concat(
      obj?.[propKey]?.trim()
                    ?.split(',')
                    ?.map(prop => ({ [propDest]: prop, ...obj })) || []
    ), [])
  },

  async getRolesList(view) {
    const collection = view === 'action' ? actionsCollection : rolesCollection
    try {
      let habilitationsList = []
      const snapshot = await collection.get()
      snapshot.forEach(doc => {
        habilitationsList.push(doc.data())
      })
      return habilitationsList
    } catch (err) {
      return []
    }
  }
  ,

  async isSuperAdmin(userCode) {
    let usersSnapshot = await usersCollection.doc(userCode.toUpperCase()).get()
    if (usersSnapshot.exists) {
      return usersSnapshot.data()?.['actions']?.split(',')?.includes('4')
    }
    return false
  },
  async saveOrUpdate(selectedHabilitation, selectedItem, isActionsView, isAddMode, userCode) {
    let actions = !isAddMode ? selectedItem?.item?.actions : null
    let roles = !isAddMode ? selectedItem?.item?.roles : null
    if (!isAddMode) {
      if (isActionsView) {
        let selectedActions = selectedItem.item.actions
        actions = selectedActions.replaceAll(selectedItem.item.id, selectedHabilitation.toString())
      } else {
        let selectedRoles = selectedItem.item.roles
        roles = selectedRoles.replaceAll(selectedItem.item.id, selectedHabilitation.toString())
      }
    } else {
      let usersSnapshot = await usersCollection.doc(userCode.toUpperCase()).get()
      if (usersSnapshot.exists) {
        selectedItem = usersSnapshot.data()
        actions = selectedItem.actions
        roles = selectedItem.roles
      }
      if (isActionsView) {
        let selectedActions = selectedItem?.actions
        actions = selectedActions ? selectedActions + ',' + selectedHabilitation : selectedHabilitation.toString()
      } else {
        let selectedRoles = selectedItem?.roles
        roles = selectedRoles ? selectedRoles + ',' + selectedHabilitation : selectedHabilitation.toString()
      }
    }

    await usersCollection.doc(userCode.toUpperCase()).set({ actions, roles }, { merge: true })
  },
  async deleteHabilitation(selectedItem, isActionView) {
    let actions = ''
    let roles = ''
    let usersSnapshot = await usersCollection.doc(selectedItem.item.userCode).get()
    if (usersSnapshot.exists) {
      actions = usersSnapshot.data()?.['actions'] || null
      roles = usersSnapshot.data()?.['roles'] || null
      if (isActionView) {
        if (actions) {
          actions = actions.split(',')
          actions.splice(actions.indexOf(selectedItem.item.id), 1)
          actions = actions.join() || null
        }
      } else {
        if (roles) {
          roles = roles.split(',')
          roles.splice(roles.indexOf(selectedItem.item.id), 1)
          roles = roles.join() || null
        }
      }

      await usersCollection.doc(selectedItem.item.userCode.toUpperCase()).set({ actions, roles })
      await this.deleteEmptyFields(actions, roles, selectedItem)
    }
  },
  async deleteEmptyFields(actions, roles, selectedItem) {
    if (!actions) {
      await usersCollection.doc(selectedItem.item.userCode.toUpperCase()).update({
        actions: firebase.firestore.FieldValue.delete()
      })
    }
    if (!roles) {
      await usersCollection.doc(selectedItem.item.userCode.toUpperCase()).update({
        roles: firebase.firestore.FieldValue.delete()
      })
    }
  }
}
