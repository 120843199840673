export class Criterion {
  constructor ({ type = '*', pattern = /.+/, hint = '' }) {
    this.type    = type
    this.pattern = pattern
    this.hint    = hint
  }
}

export class BusinessObjectConfiguration {
  constructor ({
                 type,
                 label,
                 term = '',
                 entries = [],
                 sheet = {},
                 sheetTerm = '',
                 sheetCriterion = '',
                 globalFilter = {
                   column: '',
                   value : null
                 },
                 criteria = [new Criterion()],
                 validate = (bo, criterion) => {
                   if (criterion && !criterion.pattern.test(bo.term)) {
                     return [criterion.hint]
                   }
                   return null
                 },
                 beforeSearch = (bo) => {
                 },
                 icon = '',
                 svg = '',
                 mandatoryEntries = new Set(),
                 rightsForAction = {},
                 noSearch = false
               }) {
    
    this.type             = type
    this.label            = label
    this.term             = term
    this.entries          = entries
    this.sheet            = sheet
    this.sheetTerm        = sheetTerm
    this.sheetCriterion   = sheetCriterion
    this.globalFilter     = globalFilter
    this.criteria         = criteria
    this.validate         = validate
    this.beforeSearch     = beforeSearch
    this.icon             = icon
    this.svg              = svg
    this.mandatoryEntries = mandatoryEntries
    this.rightsForAction  = rightsForAction
    this.noSearch         = noSearch
  }
}
