<template>
  <div class="smart-notifications">
    <button class="notifications-button" :class="{ ringing: unreadNotifs }" @click="opened = true">
      <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 24 24" width="1.5em">
        <path
          fill="currentColor"
          d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-1.29 1.29c-.63.63-.19 1.71.7 1.71h13.17c.89 0 1.34-1.08.71-1.71L18 16z" />
      </svg>
      <div v-if="unreadNotifs" class="chip">{{ unreadNotifs }}</div>
    </button>
    <transition name="fade">
      <div v-if="opened" class="notifications-backdrop" @click.self="close">
        <transition name="pop">
          <div class="notifications-popin">
            <button class="close" @click="close">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x">
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
            <div class="title">Notifications</div>
            <div class="subtitle">{{ subtitle }}</div>
            <div class="notifications">
              <div v-for="notif in notifications" :key="notif.id" class="notification">
                <div class="notification-header">
                  <div class="title"> {{ notif.title }} </div>
                  <div class="timestamp"> {{ `il y a ${timastamp(notif.start)}` }} </div>
                </div>
                <div class="notification-content">
                  {{ notif.text }}
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
  import { Duration } from 'luxon'

  export default {
    name: 'SmartNotifications',
    props: {
      user: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        opened: false,
        notifications: [],
        readedNotifs: []
      }
    },
    computed: {
      storageKey() {
        return `${process.env.VUE_APP_APP_NAME}:readedNotifs`
      },
      subtitle() {
        const nbNotif = this.notifications.length
        const plural = nbNotif > 1 ? 's' : ''
        return `Tu as ${nbNotif} nouvelle${plural} notification${plural}`
      },
      unreadNotifs() {
        return this.notifications.filter(n => !this.readedNotifs.includes(n.id)).length
      }
    },
    async mounted() {
      try {
        this.readedNotifs = JSON.parse(localStorage.getItem(this.storageKey)) ?? []
      } catch (e) {
        this.readedNotifs = []
      }
      await this.getNotifs()
      this.opened = this.unreadNotifs
    },
    methods: {
      close() {
        this.readedNotifs = this.notifications.map(n => n.id)
        localStorage.setItem(this.storageKey, JSON.stringify(this.readedNotifs))
        this.opened = false
      },
      async getNotifs() {
        if (!process.env.VUE_APP_BACKEND_SMART) {
          console.error(`[SmartNotifs] Missing VUE_APP_BACKEND_SMART env variable`)
          return
        }
        if (!process.env.VUE_APP_APP_NAME) {
          console.error(`[SmartNotifs] Missing VUE_APP_APP_NAME env variable`)
          return
        }
        const token = await this.user.getIdToken()
        const notificationResponse = await fetch(`${process.env.VUE_APP_BACKEND_SMART}/notif`, {
          headers: {
            Authorization: `Bearer ${token}`,
            application: process.env.VUE_APP_APP_NAME,
            country: process.env.VUE_APP_COUNTRY,
            project_id: process.env.VUE_APP_FIREBASE_PROJECT_ID
          }
        })
          .then(res => res.json())
          .catch(console.error)

          this.notifications = notificationResponse?.result

        if (!this.notifications.length) {
          localStorage.removeItem(this.storageKey)
        }
        return Promise.resolve(this.notifications)
      },
      timastamp(t) {
        const durationMillis = Date.now() - t
        const duration = Duration.fromMillis(durationMillis).shiftTo('months', 'days', 'hours', 'minutes', 'seconds').toObject()

        if (duration.months) return `${duration.months}mois`
        else if (duration.days) return `${duration.days}j`
        else if (duration.hours) return `${duration.hours}h`
        else return `${duration.minutes}min`
      }
    }
  }
</script>

<style lang="scss" scoped>
  .smart-notifications {
    color: inherit;
    .notifications-button {
      font-size: inherit;
      background: none;
      border: none;
      outline: none;
      color: inherit;
      display: flex;
      justify-content: center;
      position: relative;

      @media screen and (max-width: 1200px) {
        height: 32px;
        width: 32px;
        padding: 4px;
        background-color: var(--app-color-lighten);
        color: var(--app-color);
        border-radius: 50%;
      }
      svg {
        transform-origin: 50% 0px;
      }
      &.ringing svg,
      &:hover svg {
        animation: bell 2s ease-in-out infinite;
      }
      .chip {
        color: white;
        position: absolute;
        font-size: 9px;
        font-weight: bold;
        background-color: var(--app-color-complement);
        right: 0;
        top: 0;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .notifications-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
    backdrop-filter: blur(24px);
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1200px) {
      align-items: flex-end;
      width: 100%;
    }
    .notifications-popin {
      color: black;
      background-color: white;
      border-radius: 32px;
      padding: 32px;
      margin: 16px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      width: stretch;
      min-height: 150px;
      max-height: stretch;
      position: relative;

      .close {
        position: absolute;
        right: 32px;
        top: 32px;
        background: none;
        border: none;
        outline: none;
      }
      > .title {
        font-size: 25px;
        font-weight: 700;
      }
      > .subtitle {
        color: var(--app-color);
        font-weight: 700;
      }
      .notifications {
        overflow: auto;
        width: 100%;
        .notification {
          margin-top: 16px;
          &-header {
            display: flex;
            justify-content: space-between;
            .title {
              font-weight: 600;
            }
            .timestamp {
              font-size: 12px;
              color: #a3a3a3;
              white-space: nowrap;
            }
          }
          &-content {
            margin-top: 8px;
            font-size: 13px;
            text-align: justify;
            white-space: pre-wrap;
          }
        }
      }
    }
  }

  @keyframes bell {
    0% {
      transform: rotateZ(0);
    }
    5% {
      transform: rotateZ(20deg);
    }
    10% {
      transform: rotateZ(-18deg);
    }
    15% {
      transform: rotateZ(16deg);
    }
    20% {
      transform: rotateZ(-14deg);
    }
    25% {
      transform: rotateZ(12deg);
    }
    30% {
      transform: rotateZ(-10deg);
    }
    35% {
      transform: rotateZ(8deg);
    }
    40% {
      transform: rotateZ(-6deg);
    }
    45% {
      transform: rotateZ(4deg);
    }
    50% {
      transform: rotateZ(0);
    }
  }
  .fade-enter-active {
    transition: opacity 0.2s;
    .notifications-popin {
      animation: pop 0.3s;
    }
  }

  .fade-leave-active {
    transition: opacity 0.2s;
    transition-delay: 0.1s;
    .notifications-popin {
      animation: pop 0.2s reverse;
    }
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    .notifications-popin {
      transform: scale(0.5);
    }
  }

  @keyframes pop {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
