export default {
  modal: {
    delete: {
      header: 'Confirmation de suppression',
      body: 'Etes-vous sûr de vouloir supprimer l\'autorisation pour l\'utilisateur',
      footer: {
        delete: 'Supprimer',
        cancel: 'Annuler'
      }
    }
  },
  buttons: {
    add: 'Ajouter',
    edit: 'Modifier',
    cancel: 'Annuler'
  },
  labels: {
    roles: 'Rôles',
    actions: 'Actions'
  },
  headers: {
    userCode: 'Matricule',
    firstName: 'Prénom',
    lastName: 'Nom',
    roles: 'Rôles',
    actions: 'Actions'
  }
}