<template>
  <div class="modal-refresh backdrop" @click.self="$emit('close')">
    <div class="modal pop">
      <div class="title">Nouvelle version</div>
      <div class="content">
        Une nouvelle version de Hibou est disponible. <br>
        Voulez-vous recharger la page maintenant ?
      </div>
      <div class="actions">
        <button class="close button" @click="$emit('close')">Fermer</button>
        <button class="refresh button" @click="$emit('refresh')">Recharger</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ModalRefresh'
  }
</script>

<style lang="scss" scoped>

  .modal-refresh {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 16px;
    z-index: 500;
    backdrop-filter: blur(12px);
    background-color: rgba(0, 0, 0, 0.2);
    padding-right: 16px;
    padding-bottom: 16px;

    .modal {
      width: 100%;
      color: black;
      background-color: white;
      border-radius: 32px;
      padding: 32px;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
      max-width: 500px;

      .title {
        font-size: 25px;
        font-weight: 700;
      }
      .content {
        margin: 16px 0;
      }
      .actions {
        display: flex;
        justify-content: flex-end;
        .button {
          border: none;
          background: none;
          font-size: inherit;
          margin-left: 8px;
          outline: none;
          border-radius: 8px;
          padding: 8px 16px;
          &.refresh {
            background: #40B883FF;
            box-shadow: 0px 2px 8px #40B883FF;
            color: white;
            font-weight: 700;
          }
          &.close {
            color: grey;
          }
        }
      }
    }
  }
</style>
