<template>
  <vodal id="toto" className="historyModal" :show="show" animation="door"
    @hide="$emit('hide-history')" :close-on-esc="true"
    :customStyles="{overflow: 'hidden', height: 'auto', 'max-height': '70vh'}">
    <div class="reporting-log">
      <h3 class="header">Historique</h3>

      <div class="reporting-wrapper">
        <div class="reporting-wrapper__items" v-for="(report, index) of history" :key="index">
          <div class="reporting-wrapper__items__top">
            <div class="reporting-wrapper__items__top__name">{{ report.userName}}</div>
            <div class="reporting-wrapper__items__top__date">{{ report.creationDate | morph-date('D MMMM YYYY hh:mm') }}</div>
          </div>
          <div class="reporting-wrapper__items__message">
            <span>{{ report.message }}</span>
          </div>
        </div>
      </div>
    </div>
  </vodal>
</template>
<script>
import Vodal from 'vodal';
import "vodal/common.css";
import "vodal/door.css";

export default {
  components : {
    [Vodal.name]: Vodal
  },
  props: {
    history: {
      type: Array,
      default () { return [] }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
}
</script>
<style>
.historyModal .vodal-dialog .vodal-close {
  position: relative !important;
  top: 0;
  right: 0;
  cursor: pointer !important;
  width: 16px !important;
  height: 16px !important;
  float: right !important;
  display: block !important;
  margin: 0 0 8px !important;
}
</style>

<style lang="scss" scoped>
@import "~@/sass/main";

.reporting-log {
  padding: 0 8px;
  height: 95%;
  clear: both;

  .header {
    padding: 0 0 8px;
    border-bottom: 1px solid rgba(0,0,0, 0.05);
    height: 2rem;
  }

  .reporting-wrapper {
    overflow: auto;
    height: calc(100% - 2.5rem);
    padding: 0 16px;


    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: darken($side-bar-color, 4%);

    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      border-radius: 10px;
      background: transparent;
    }

    &__items {
      background-color: #ededed;
      padding: 8px 16px;
      min-height: 3em;
      margin-top: 1em;
      border-radius: 5px;
      box-shadow: 0 5px 20px rgba(#555, .1);
      overflow: auto;


      &__top {

        &__name {
          transition: color .1s ease;
          font-size: 1.3em;
          font-weight: 700;
          color: $side-bar-color;
        }
        &__date {
          margin: .5em 0 .5em;
          font-size: 0.8em;
          color: rgba(0,0,0,.6);
        }
      }
      &__message {
        margin-top: .6em;
        max-width: auto;
        font-size: 1em;
        line-height: 1.4285em;
        color: rgba(#555,.87);
      }
    }
  }

}

</style>
