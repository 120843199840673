import { mapGetters } from 'vuex'
import i18n from './supplier.i18n'
import moment from 'moment'

export default {
  data () {
    return {
      i18n
    }
  },
  computed: {
    ...mapGetters(['sheet']),
    
    technical () {
      return {
        id: this.sheet.id,
        updatingDate: this.sheet.technicalData.updatingDate
      }
    },
    
    labelsAndNomenclature () {
      return {
        supplierType: this.sheet.data.supplierType,
        phone: this.sheet.data.phone,
        fax: this.sheet.data.fax,
        address: ((this.sheet.data.address1 || '') + ' ' + (this.sheet.data.address2 || '')).trim()
      }
    },
  
    datesAndState () {
      const now = moment()
      const stoppingDate = moment(this.sheet.data.stoppingDate, 'YYYY-MM-DD')
      const deletionDate = moment(this.sheet.data.deletionDate, 'YYYY-MM-DD')
      return {
        creationDate: this.sheet.technicalData.creationDate,
        stoppingDate: this.sheet.technicalData.stoppingDate,
        removingDate: this.sheet.technicalData.deletionDate,
        willBeStopped: !!this.sheet.data.stoppingDate && stoppingDate.isAfter(now),
        willBeRemoved: !!this.sheet.data.deletionDate && deletionDate.isAfter(now),
        isStopped: !!this.sheet.technicalData.stoppingDate && stoppingDate.isBefore(now),
        isRemoved: !!this.sheet.technicalData.deletionDate && deletionDate.isBefore(now)
      }
    }
  }
}
