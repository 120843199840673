import Tour from 'tour/dist/tour'

const steps = [
  {
    target: `nav .bo.current`, content: `L'objet courant`
  },
  {
    target: `.sidebar .filter`,
    content: `Filtre global qui permet de filtrer tous les tableaux par rapport à la colonne et valeur choisies`
  },
  {
    target: `.sidebar .entries`,
    content: `Les entrées: elles permettent d'afficher plus d'informations concernant l'objet courant `
  },
  {
    target: '.collapse-btn',
    content: 'Réduire/Afficher la barre latérale'
  },
  {
    target: `.sidebar .entries .switch`, content: `Active/Désactive une entrée`
  },
  {
    target: `.search`, content: `La barre de recherche`
  },
  {
    target: `.search .criterion`, content: `Le type du critère sur lequel la recherche est effectuée`
  },
  {
    target: `#entry-sheet`, content: `La signalétique de l'objet trouvé`
  },
  {
    target: `.table`, content: `Les données liées à l'entrée selectionnée`
  },
  {
    target: `.download`, content: `Télécharge le tableau sous forme de CSV`
  },
  {
    target: `.filter-icon`, content: `Affiche le filtre de la colonne`
  },
  {
    target: `th .sort`, content: `Cliquez pour trier`
  }
]

export function showTour ({bo, activeEntries}) {
  let customSteps = [...steps]
  if (!bo.sheet || !bo.sheet.id) {
    customSteps.splice(6, 5)
  } else {
    if (!activeEntries || !activeEntries.length) {
      customSteps.splice(7, 4)
    }
  }
  const myTour = {
    canExit: true,
    previousText: '<i class="fa fa-arrow-left"></i>',
    nextText: '<i class="fa fa-arrow-right"></i>',
    finishText: 'C\'est fini!',
    maskVisible: true,
    dark: false,
    disableInteraction: true,
    scrollBox: 'main',
    maskScrollThrough: true,
    steps: customSteps
  }

  Tour.start(myTour)
}
