import Vue from 'vue'
import VTooltip from 'v-tooltip'
import Toast from 'vue-easy-toast/dist/vue-easy-toast-lite.min.js'
import App from '@/App'
import 'firebase/compat/analytics'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import router from '@/router'
import store from '@/stores'
import userStore from '@/user/userStore'

import '../node_modules/font-awesome/css/font-awesome.min.css'
import './sass/main.scss'
import 'vue-easy-toast/dist/vue-easy-toast.css'
import '../node_modules/tour/dist/tour.css'
import NavScroll from '@/navscroll'

import '@/components/base'

firebase.firestore().settings({ ignoreUndefinedProperties: true, experimentalAutoDetectLongPolling: true })

Vue.config.productionTip = false

Vue.use(NavScroll)
Vue.use(Toast)
Vue.use(VTooltip, { disposeTimeout: 100 })

export default async function createVueApp({ firebaseUser, user, roles }) {
  await addPropertiesOnVuejsPrototype({ firebaseUser, user, roles })

  return new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}

async function addPropertiesOnVuejsPrototype({ firebaseUser, user, roles }) {
  Vue.prototype.$user = firebaseUser
  Vue.prototype.$userInfos = user
  Vue.prototype.$roles = roles
  Vue.prototype.$canViewProductMargin = user.site?.contractType === 'INT'
  userStore.token = await firebaseUser.getIdToken()
  userStore.user = user
}

