<template>
  <div id="app">
    <template v-if="splashHidden">
      <ui-sidebar class="menu"></ui-sidebar>
      <router-view class="content"></router-view>
      <div class="floating-logo">
        <div class="version">version 1.0.6</div>
        <v-svg style="height: 8em">by-smart</v-svg>
      </div>
    </template>
    <splash-screen v-else-if="isAuthenticated()"></splash-screen>
    <modal-refresh v-if="hasNewContent" @close="hasNewContent = false" @refresh="refreshApp" />
  </div>
</template>

<script>
  import messageService from '@/services/messaging.service'
  import store from '@/stores'
  import Sidebar from '@/components/Sidebar'
  import SplashScreen from '@/components/SplashScreen'
  import { mapActions, mapGetters } from 'vuex'
  import Vue from 'vue'

  export default {
    name: 'app',
    store,
    components: {
      'ui-sidebar': Sidebar,
      'splash-screen': SplashScreen
    },
    data() {
      return {
        showSplashScreen: false,
        hasNewContent: false,
        newRegistration: null,
        refreshing: false
      }
    },

    async created() {
      document.addEventListener('swUpdated', this.showRefreshUI, { once: true })

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          if (this.refreshing) return
          // eslint-disable-next-line no-console
          console.info('[Service worker] refreshing app')
          this.refreshing = true
          window.location.reload()
        })
      }
    },

    mounted() {
      store.dispatch('init').then(() => {
        store.commit('loaded')
      })
      setTimeout(() => {
        this.showSplashScreen = false
        setTimeout(() => {
          if (!this.splashHidden) messageService.showInfo('Le chargement est un peu long, une erreur est peut être survenue ! Rechargez la page si rien ne change.')
        }, 15000)
      }, 3000)
    },


    computed: {
      ...mapGetters(['businessObjects', 'initFinished']),
      splashHidden() {
        return !this.showSplashScreen && this.initFinished
      }
    },

    methods: {
      ...mapActions(['setRightsForAction']),

      isAuthenticated() {
        // console.log('App is authenticated')
        return Vue.prototype.$userInfos
      },

      showRefreshUI(e) {
        this.newRegistration = e.detail
        this.hasNewContent = true
      },

      refreshApp() {
        this.hasNewContent = false

        // Protect against missing registration.waiting.
        if (!this.newRegistration || !this.newRegistration.waiting) return

        this.newRegistration.waiting.postMessage({ type: 'SKIP_WAITING' })
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/sass/main.scss";

  #app {
    height: 100%;
    overflow: hidden;

    .menu {
      float: left;
      height: 100vh;
    }

    .content {
      overflow: hidden;
      position: relative;
      height: 100%;
      background-color: $background-color;

      &.welcome {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .floating-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 1rem;
    left: calc(15rem + 1px);
    box-shadow: 0 0 10px rgba(0,0,0,.3);
    background: white;
    transform: rotate(180deg);
    padding: 10px 3px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    .version {
      font-size: 0.7rem;
      font-weight: bold;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
    }
  }

</style>
