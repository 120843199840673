import { BusinessObjectConfiguration } from './bo.conf'

export default class IGRValuesConfiguration extends BusinessObjectConfiguration {
  constructor () {
    super({
      label           : 'Tables De Valeurs',
      type            : 'igrValues',
      criteria        : [],
      icon            : 'fa-cog',
      mandatoryEntries: new Set([]),
      rightsForAction : {},
      noSearch        : true
    })
  }
}
