<template>
  <i :style="style" class="v-icon" v-html="icon" />
</template>

<script>
  export default {
    name: 'VSvg',

    props: {
      size: {
        type: String,
        default: '1em'
      },

      fontSize: {
        type: String,
        default: '1em'
      },

      strokeWidth: {
        type: String,
        default: '0'
      },
      fill: {
        type: String,
        default: 'none'
      }
    },

    data() {
      return {
        icon: null
      }
    },

    computed: {
      style() {
        return {
          width: this.size,
          height: this.size,
          fontSize: this.fontSize,
          color: this.color,
          '--v-icon-stroke-width': this.strokeWidth,
          '--v-icon-fill': this.fill
        }
      }
    },

    mounted() {
      this.setIcon()
    },

    updated() {
      this.setIcon()
    },

    methods: {
      setIcon() {
        const icon = this.$slots.default[0].text.trim()
        try {
          this.icon = require(`!svg-inline-loader!@/assets/svg/${ icon }.svg`)
        } catch (e) {
          this.icon = icon
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;

    :global(svg) {
      width: 1em;
      stroke: currentColor;
      stroke-width: var(--v-icon-stroke-width);
      fill: var(--v-icon-fill);
      transition: stroke-width 0.5s ease-in-out;
    }
  }
</style>
