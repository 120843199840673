export default {
  technical: {
    id: 'NSU_FRN',
    updatingDate: 'MAJ'
  },
  
  labelsAndNomenclature: {
    supplierType: 'Type',
    phone: 'Téléphone',
    fax: 'Fax',
    email: 'Email contact',
    address: 'Adresse'
  },
  
  datesAndState: {
    creationDate: 'Créé le',
    stoppingDate: 'Arrêté le',
    removingDate: 'Supprimé le'
  }
}
