export default {
  initFinished: (state) => state.loaded,

  businessObjects: (state) => state.businessObjects,

  bo: (state) => state.currentBO,

  boIndexByType: state => boType => state.businessObjects.findIndex(bo => bo.type === boType),

  boByType: (state, getters) => boType => {
    let i = getters.boIndexByType(boType);
    return !!~i ? state.businessObjects[i] : undefined
  },


  criterion: (state) => state.currentBO.currentCriterion,

  criterionByType: (state) => cType => bo =>
    (bo || state.currentBO).criteria.find(c => c.type.toLowerCase() === cType.toLowerCase()),

  term: (state) => state.currentBO.term.trim(),

  sheet: (state) => state.currentBO.sheet,

  entries: (state) => state.currentBO.entries,

  globalFilter: (state) => state.currentBO.globalFilter,

  activeEntries: (state) => state.currentBO.entries ? state.currentBO.entries.filter(entry => entry.isMandatory || entry.selected) : [],

  allColumns: (state, getters) => {
    const map = {}
    getters.activeEntries.forEach(entry => {
      if (entry.table) {
        entry.table?.columns?.forEach(column => {
          map[column.field] = (map[column.field] || 0) + 1
        })
      }
    })
    const allColumns = []
    Object.entries(map).forEach(counter => {
      if (counter[1] > 1) allColumns.push(counter[0])
    })
    return allColumns.sort()
  },

  actions: (state, getters) => Object.keys(getters.bo.rightsForAction).filter(action => getters.bo.rightsForAction[action].value)
    .map(key => ({...getters.bo.rightsForAction[key], type: key})),

  hasAction: (state) => actionType => state.currentBO.rightsForAction[actionType] && state.currentBO.rightsForAction[actionType].value
}
