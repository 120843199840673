import EventBus from '@/bus/event.bus'
import ProductSheet from '@/components/ProductSheet'
import Search from '@/components/Search'
import SupplierSheet from '@/components/SupplierSheet'
import GrantAccess from '@/components/GrantAccess'
import Svg from '@/components/base/Svg'
import Table from '@/components/Table'
import UserActions from '@/components/UserActions'
import NavScroll from '@/navscroll'
import resolver from '@/services/bo-resolver.service'
import genericService from '@/services/generic.service'
import Welcome from '@/views/Welcome'
import { throttle, debounce } from 'lodash'
import SyncLoader from 'vue-spinner/src/SyncLoader'
import { mapGetters, mapMutations } from 'vuex'


export default {
  components: {
    'ui-welcome': Welcome,
    'ui-actions': UserActions,
    'ui-search': Search,
    'ui-products-sheet': ProductSheet,
    'ui-suppliers-sheet': SupplierSheet,
    'ui-grant-access': GrantAccess,
    'ui-table': Table,
    'sync-loader': SyncLoader,
    'v-svg': Svg
  },

  mounted() {
    NavScroll.initScrollHandler()
  },

  computed: {
    ...mapGetters(['businessObjects', 'bo', 'sheet', 'criterion', 'term', 'entries', 'activeEntries']),

    user() {
      return this.$userInfos || {}
    }
  },

  watch: {
    async bo(bo) {
      if (bo.noSearch) {
        await this.getEntriesDatas()
        this.$emit('hibou-result-update')
      }
    }
  },

  methods: {
    ...mapMutations(['setCurrentBO', 'setSheet']),

    exportCSV(entry) {
      const CSV_SEPARATOR_CHAR = ';'
      const CSV_END_OF_LINE_CHAR = '\n'
      const UTF8_HEADER = '\ufeff'
      const fields = entry.table.columns.map(column => column.field)
      const header = fields.join(CSV_SEPARATOR_CHAR) + CSV_END_OF_LINE_CHAR
      const values = entry.table.values
                          .map(line => fields.map(header => line[header]).join(CSV_SEPARATOR_CHAR))
                          .join(CSV_END_OF_LINE_CHAR)

      const blob = new Blob([UTF8_HEADER, header, values], { type: 'text/csv;charset=utf-8;' })
      const a = document.createElement('a')
      a.setAttribute('style', 'display: none')
      document.body.appendChild(a)
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = `${ this.bo.type }#${ this.sheet.id }-${ entry.name }.csv`
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    },


    addShadow: throttle(function(event) {
      if (event.target.scrollTop > 10) {
        this.$refs.bos.classList.add('with-shadow')
      } else {
        this.$refs.bos.classList.remove('with-shadow')
      }
      this.scroll()
    }, 100),

    getEntriesDatas: debounce(function() {
      return new Promise((resolve, reject) => {
        if (!(this.bo.noSearch || (this.sheet && this.sheet.id))) return resolve()
        this.activeEntries.forEach(async entry => {
          try {
            entry.table = null
            let table = await genericService.getTable({
              bo: this.bo.type,
              boId: this.sheet && this.sheet.id,
              entryId: entry.id
            })
            console.log(`[${ this.bo.type } (${ this.sheet.id }) - entry ${ entry.id }] fetch table`)
            entry.table = Array.isArray(table) ? { columns: [], values: [] } : table
          } catch (e) {
          }
        })
        resolve()
      })
    }, 100),

    scroll(event) {
      EventBus.$emit('hibou-scroll', event)
    },

    click(event) {
      EventBus.$emit('hibou-click', event)
    },

    openRosa() {
      window.open('https://rosa.dataauchan.fr/', 'blank')
    }

  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      await vm.getEntriesDatas()
      vm.$emit('hibou-result-update')
    })
  },
  beforeRouteUpdate(to, from, next) {
    resolver(to, async() => {
      await this.getEntriesDatas().then(() => next())
      this.$emit('hibou-result-update')
    })
  }
}
