import {BusinessObjectConfiguration, Criterion} from './bo.conf'

export default class SupplierConfiguration extends BusinessObjectConfiguration {
  constructor() {
    super({
      label: 'fournisseurs',
      type: 'suppliers',
      criteria: [
        new Criterion({type: 'FRN', pattern: /(^([Aa][pP])?\d{5}$)/, hint: 'Saisissez 5 caractères ou AP + 5 chiffres'}),
        new Criterion({type: 'FCO', pattern: /^.{5,}$/, hint: 'Saisissez au moins 5 caractères'}),
        new Criterion({type: 'ID', pattern: /^.{5,}$/, hint: 'Saisissez au moins 5 caractères'})],
      icon: 'fa-truck',
      mandatoryEntries: new Set([2001]),
      rightsForAction: {
        userCanAskRepublish: {
          value: false,
          label: 'Republier',
          icon: 'fa-repeat'
        }
      }
    })
  }
}
