import ProductConf from './product.conf'
import SupplierConf from './supplier.conf'
import IGRValuesConfi from './igrValues.conf'
import GrantAccess from './grantAccess.conf'

import chipmunkService from '../services/chipmunk.service'
import userStorageService from '@/user/userStore'

export function reset(currentState) {
  const businessObjects = [new ProductConf(), new SupplierConf(), new IGRValuesConfi()]

  if (currentState) {
    currentState.businessObjects.forEach((bo, i) => {
      businessObjects[i].entries = bo.entries.map(e => ({ ...e, table: null }))
      businessObjects[i].rightsForAction = bo.rightsForAction
    })

  }
  businessObjects.forEach(bo => {
    bo.currentCriterion = bo.criteria[0]
  })
  setTimeout(() => initSuperAdmin(currentState), 0)
  return {
    loaded: !!currentState,
    businessObjects,
    currentBO: businessObjects[0]
  }
}

const state = reset()

async function initSuperAdmin($state = state) {
  const user = userStorageService.user
  const isSuperAdmin = await chipmunkService.isSuperAdmin(user.employeeNumber)
  if (isSuperAdmin) {
    $state.businessObjects.push(new GrantAccess())
  }
}


export default state
