import { mapGetters, mapActions } from 'vuex'
import genericService from '@/services/generic.service'
import { showTour } from '@/tour'

export default {
  data() {
    return {
      menuIsOpen: true
    }
  },

  watch: {
    'globalFilter.column'() {
      this.globalFilter.value = null
    }
  },

  computed: {
    ...mapGetters(['globalFilter', 'allColumns', 'activeEntries', 'bo', 'entries', 'sheet']),

    user() {
      return this.$userInfos
    },

    filterAvailableValues() {
      if (this.globalFilter.column) {
        const all = new Set()
        this.activeEntries.forEach(entry => {
          if (entry.table) {
            entry.table.values.forEach(line => all.add(line[this.globalFilter.column]))
          }
        })
        all.delete(undefined)
        all.delete(null)
        return [...all]
      }
      return []
    }
  },

  methods: {
    ...mapActions(['reset']),

    resetAppState() {
      this.$router.push('/')
      this.reset()
    },

    toggleEntry(entry) {
      entry.selected = !entry.selected
      localStorage.setItem(
        this.bo.type + ':selectedEntries',
        JSON.stringify(this.entries.filter(entry => entry.selected).map(e => e.id))
      )

      if (this.bo.noSearch || (entry.selected && this.sheet && this.sheet.id)) {
        genericService
          .getTable({
            bo: this.bo.type,
            boId: this.sheet.id,
            entryId: entry.id
          })
          .then(table => entry.table = Array.isArray(table) ? { columns: [], values: [] } : table)
      }
    },

    showTour() {
      showTour({ bo: this.bo, activeEntries: this.activeEntries })
    },

    openCommunity() {
      window.open(process.env.VUE_APP_COMMUNITY_URL, 'blank')
    },

    openDocumentation() {
      window.open(process.env.VUE_APP_DOCUMENTATION_URL, 'blank')
    }
  }
}
