import { render, staticRenderFns } from "./Table.html?vue&type=template&id=60e6e23f&scoped=true&lang=html&external"
import script from "./Table.js?vue&type=script&lang=js&external"
export * from "./Table.js?vue&type=script&lang=js&external"
import style0 from "./Table.scss?vue&type=style&index=0&id=60e6e23f&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60e6e23f",
  null
  
)

export default component.exports