<template>
  <div>

    <div class="reporting-form">
      <form ref="reportForm" @submit.prevent="submit(); $refs.reportForm.reset()">
        <div class="reporting-form__title">
          <i class="fa fa-comment" aria-hidden="true"></i>
          <span>Signaler</span>
          <span class="reporting-form__title__counter">&nbsp;({{maxLengthMsg - message.length}} caractères restants)</span>
          <span class="reporting-form__title__history" v-if="hasHistory"
            @click="displayReportHistoryLogs = !displayReportHistoryLogs">
            <i class="fa fa-align-right" aria-hidden="true"></i>Historique
          </span>
        </div>


        <div class="reporting-form__form">
          <textarea
            v-model="message"
            name="message"
            :maxlength="maxLengthMsg"
            required>
          </textarea>
          <button :disabled="message.length <= 0 || message.length > maxLengthMsg" type="submit">Envoyer</button>
        </div>
      </form>
    </div>

    <!-- modal -->
    <ui-reporting-log-modal
      :history="history"
      :show="displayReportHistoryLogs"
      @hide-history="displayReportHistoryLogs = false">
    </ui-reporting-log-modal>

  </div>
</template>

<script>
import ReportingLog from '@/components/ReportComposer/reporting-log.vue'

export default {
  components : {
    'ui-reporting-log-modal': ReportingLog
  },
  props: {
    history: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      maxLengthMsg: 1000,
      message: '',
      displayReportHistoryLogs: false
    }
  },

  computed: {
    hasHistory() { return this.history.length }
  },

  methods: {
    submit() {
      this.$emit('new-report', this.message)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/sass/main";

.reporting-form {
  padding: 16px;
  background-color: #e6faef;

  &__title {
    align-items: baseline;
    align-content: center;
    display: flex;
    font-size: 0.9rem;
    .fa {
      margin-right: 1rem;
    }
    &__counter{
      font-size: 1rem;
    }
    &__history{
      margin-left: auto;
      line-height: 1.4rem;

      .fa {
        margin-right: 1rem;
      }
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    color: #32ba69;
  }
  &__form {
    position: relative;
    textarea {
      border: none;
      width: 100%;
      border-radius: 12px;
      height: 6rem;
      margin-top: 1rem;
      padding: 1rem 9rem 1rem 1rem;
      box-sizing: border-box;
      resize: none;
      &:focus {
        outline: medium solid rgba(72,91,85, 0.4);
      }
    }
    button {
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      padding: 8px 16px;
      background-color: $action-bar-color;
      color: #FFF;
      border: 0;
      border-radius: 1rem;
      box-sizing: border-box;
      text-align: center;
      text-transform: capitalize;
      transition: transform linear .1s,background-color linear .1s,-webkit-transform linear .1s;
      &:active {
        transform: scale(.97);
      }

      &:hover {
        background-color: #0a6e32;
        cursor: pointer;
      }

      &:disabled {
        cursor: not-allowed;
        background-color: #A9BEB7;
      }
    }
  }

}

</style>
